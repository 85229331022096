import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["panel"];
	static classes = ["panel"];

	toggle() {
		this.panelClasses.map((className) =>
			this.panelTarget.classList.toggle(className),
		);
	}

	hide(e) {
		if (this.element.contains(e.target)) return;
		this.panelTarget.classList.add(...this.panelClasses);
	}
}
