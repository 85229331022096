import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["inputOtherReason", "wrapperOtherReason"];

	onChangeReason(e) {
		if (e.target.value === "other") {
			this.showOtherReason();
			return;
		}

		this.hideAndClearOtherReason();
	}

	showOtherReason() {
		this.wrapperOtherReasonTarget.classList.remove("hidden");
	}

	hideAndClearOtherReason() {
		this.wrapperOtherReasonTarget.classList.add("hidden");
		this.inputOtherReasonTarget.value = "";
	}
}
