import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  clearLocalStorage(e) {
    const confirm = e.detail[0];
    if (!confirm) return;

    localStorage.clear();
  }
}
