/**
 * File generated by js-routes 2.2.4
 * Based on Rails 7.0.8.4 routes of Eddy::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /users/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_user_invitation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_admin_user_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admin_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_admin_users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admin_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comment_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comments_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_companies_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_company_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_dashboard_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/global_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_exports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"global_exports"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/global_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_global_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"global_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/invoices_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_invoices_exports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invoices_exports"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/invoices_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_invoices_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invoices_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/provider_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_provider_account_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/provider_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_provider_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/provider_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_provider_accounts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/provider_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_provider_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_source_template_spec_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_source_template_spec_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/source_template_specs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_source_template_specs_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_template_specs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_source_template_specs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/source_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_source_templates_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_templates"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_source_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_unlock_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/vat_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_vat_exports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"vat_exports"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/vat_exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_vat_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"vat_exports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/advances/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_advance_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"advances"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/advances/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_advance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"advances"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/advances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_advances_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"advances"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/advances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_advances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"advances"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artist_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artist_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/advances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_advances_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"advances"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/advances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_advances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"advances"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_costs_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"costs"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_costs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"costs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_countries_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_countries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/countries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_country_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/countries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_country_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/graphql(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_graphql_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"graphql"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/graphql(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_graphql_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"graphql"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/payee_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_payee_user_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payee_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/payee_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_payee_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payee_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/payee_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_payee_users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payee_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/payee_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_payee_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payee_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/payment_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_payment_fields_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payment_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/payment_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_payment_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payment_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/statements/:statement_id/exports(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_statement_exports_url = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/statements/:statement_id/exports(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_statement_exports_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/statements/:statement_id/statement_lines(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_statement_statement_lines_url = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/statements/:statement_id/statement_lines(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_statement_statement_lines_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_statements_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_artists_statements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_attachment_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/attachments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_attachments_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/attachments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_attachments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/billing/tax_id_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_billing_tax_id_types_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"tax_id_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/billing/tax_id_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_billing_tax_id_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"tax_id_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/catalog/artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_artists_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/catalog/artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_artists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/catalog/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_exports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/catalog/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/catalog/merchandisings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_merchandisings_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/catalog/merchandisings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_merchandisings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/catalog/releases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_release_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/catalog/releases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_release_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/catalog/releases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_releases_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/catalog/releases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_releases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/catalog/tracks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_tracks_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/catalog/tracks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_tracks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/catalog/videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_videos_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/catalog/videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_catalog_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/categories_costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_categories_costs_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"categories_costs"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/categories_costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_categories_costs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"categories_costs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/channels/:channel_id/rebates/:id(.:format)
 * @param {any} channel_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_rebate_url = __jsr.r({"channel_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"rebates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/channels/:channel_id/rebates/:id(.:format)
 * @param {any} channel_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_rebate_path = __jsr.r({"channel_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"rebates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/channels/:channel_id/rebates(.:format)
 * @param {any} channel_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_rebates_url = __jsr.r({"channel_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"rebates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/channels/:channel_id/rebates(.:format)
 * @param {any} channel_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_rebates_path = __jsr.r({"channel_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"rebates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/channels/:channel_id/specific_contents/:id(.:format)
 * @param {any} channel_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_specific_content_url = __jsr.r({"channel_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"specific_contents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/channels/:channel_id/specific_contents/:id(.:format)
 * @param {any} channel_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_specific_content_path = __jsr.r({"channel_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"specific_contents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/channels/:channel_id/specific_contents(.:format)
 * @param {any} channel_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_specific_contents_url = __jsr.r({"channel_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"specific_contents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/channels/:channel_id/specific_contents(.:format)
 * @param {any} channel_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_specific_contents_path = __jsr.r({"channel_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"specific_contents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/channels/:channel_id/specific_percentages/:id(.:format)
 * @param {any} channel_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_specific_percentage_url = __jsr.r({"channel_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"specific_percentages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/channels/:channel_id/specific_percentages/:id(.:format)
 * @param {any} channel_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_specific_percentage_path = __jsr.r({"channel_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"specific_percentages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/channels/:channel_id/specific_percentages(.:format)
 * @param {any} channel_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_specific_percentages_url = __jsr.r({"channel_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"specific_percentages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/channels/:channel_id/specific_percentages(.:format)
 * @param {any} channel_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_channel_specific_percentages_path = __jsr.r({"channel_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"channel_id"],[2,[7,"/"],[2,[6,"specific_percentages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/ad_conditions/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_ad_condition_url = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"ad_conditions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/ad_conditions/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_ad_condition_path = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"ad_conditions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/ad_conditions/:ad_condition_id/steps/:id(.:format)
 * @param {any} contract_id
 * @param {any} ad_condition_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_ad_condition_step_url = __jsr.r({"contract_id":{"r":true},"ad_condition_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"ad_conditions"],[2,[7,"/"],[2,[3,"ad_condition_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/ad_conditions/:ad_condition_id/steps/:id(.:format)
 * @param {any} contract_id
 * @param {any} ad_condition_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_ad_condition_step_path = __jsr.r({"contract_id":{"r":true},"ad_condition_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"ad_conditions"],[2,[7,"/"],[2,[3,"ad_condition_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/ad_conditions/:ad_condition_id/steps(.:format)
 * @param {any} contract_id
 * @param {any} ad_condition_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_ad_condition_steps_url = __jsr.r({"contract_id":{"r":true},"ad_condition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"ad_conditions"],[2,[7,"/"],[2,[3,"ad_condition_id"],[2,[7,"/"],[2,[6,"steps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/ad_conditions/:ad_condition_id/steps(.:format)
 * @param {any} contract_id
 * @param {any} ad_condition_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_ad_condition_steps_path = __jsr.r({"contract_id":{"r":true},"ad_condition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"ad_conditions"],[2,[7,"/"],[2,[3,"ad_condition_id"],[2,[7,"/"],[2,[6,"steps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/ad_conditions(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_ad_conditions_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"ad_conditions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/ad_conditions(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_ad_conditions_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"ad_conditions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/amendments(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_amendments_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"amendments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/amendments(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_amendments_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"amendments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/channels/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_channel_url = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/channels/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_channel_path = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/channel_batches(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_channel_batches_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"channel_batches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/channel_batches(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_channel_batches_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"channel_batches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/channels(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_channels_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"channels"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/channels(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_channels_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"channels"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/deductions/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_deduction_url = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"deductions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/deductions/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_deduction_path = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"deductions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/deductions(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_deductions_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"deductions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/deductions(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_deductions_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"deductions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_url = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_path = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/channels/:id(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_channel_url = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/channels/:id(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_channel_path = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/channels(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_channels_url = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"channels"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/channels(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_channels_path = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"channels"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/rates/:id(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_rate_url = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"rates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/rates/:id(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_rate_path = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"rates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/steps/:id(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_step_url = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/steps/:id(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_step_path = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/steps(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_steps_url = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"steps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/steps(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_steps_path = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"steps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/support_equivalences/:id(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_support_equivalence_url = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"support_equivalences"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds/:global_threshold_id/support_equivalences/:id(.:format)
 * @param {any} contract_id
 * @param {any} global_threshold_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_threshold_support_equivalence_path = __jsr.r({"contract_id":{"r":true},"global_threshold_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[2,[7,"/"],[2,[3,"global_threshold_id"],[2,[7,"/"],[2,[6,"support_equivalences"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_thresholds_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/global_thresholds(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_global_thresholds_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"global_thresholds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/merchandisings/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_merchandising_url = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"merchandisings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/merchandisings/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_merchandising_path = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"merchandisings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/merchandisings(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_merchandisings_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"merchandisings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/merchandisings(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_merchandisings_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"merchandisings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/publish(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_publish_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/publish(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_publish_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/releases/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_release_url = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/releases/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_release_path = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/releases/:release_id/tracks/:id(.:format)
 * @param {any} contract_id
 * @param {any} release_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_release_track_url = __jsr.r({"contract_id":{"r":true},"release_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/releases/:release_id/tracks/:id(.:format)
 * @param {any} contract_id
 * @param {any} release_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_release_track_path = __jsr.r({"contract_id":{"r":true},"release_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/releases/:release_id/tracks(.:format)
 * @param {any} contract_id
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_release_tracks_url = __jsr.r({"contract_id":{"r":true},"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/releases/:release_id/tracks(.:format)
 * @param {any} contract_id
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_release_tracks_path = __jsr.r({"contract_id":{"r":true},"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/releases(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_releases_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/releases(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_releases_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/rightholders/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_rightholder_url = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"rightholders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/rightholders/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_rightholder_path = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"rightholders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/rightholders(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_rightholders_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"rightholders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/rightholders(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_rightholders_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"rightholders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/rightholders/suggestions(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_rightholders_suggestions_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"rightholders"],[2,[7,"/"],[2,[6,"suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/rightholders/suggestions(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_rightholders_suggestions_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"rightholders"],[2,[7,"/"],[2,[6,"suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/shares/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_share_url = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/shares/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_share_path = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"shares"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/shares(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_shares_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"shares"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/shares(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_shares_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"shares"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/videos/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_video_url = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/videos/:id(.:format)
 * @param {any} contract_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_video_path = __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/video_channels(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_video_channels_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"video_channels"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/video_channels(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_video_channels_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"video_channels"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/videos(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_videos_url = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:contract_id/videos(.:format)
 * @param {any} contract_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contract_videos_path = __jsr.r({"contract_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contracts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/bulk_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contracts_bulk_imports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"bulk_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/bulk_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_contracts_bulk_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"bulk_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_costs_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"costs"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_costs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"costs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/costs/bulk_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_costs_bulk_imports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"costs"],[2,[7,"/"],[2,[6,"bulk_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/costs/bulk_imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_costs_bulk_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"costs"],[2,[7,"/"],[2,[6,"bulk_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_countries_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_countries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/countries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_country_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/countries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_country_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/file_headers_extraction(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_file_headers_extraction_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"file_headers_extraction"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/file_headers_extraction(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_file_headers_extraction_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"file_headers_extraction"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/graphql(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_graphql_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"graphql"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/graphql(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_graphql_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"graphql"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/imports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_import_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/imports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_import_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/imports/:import_id/lines/:id(.:format)
 * @param {any} import_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_import_line_url = __jsr.r({"import_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"import_id"],[2,[7,"/"],[2,[6,"lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/imports/:import_id/lines/:id(.:format)
 * @param {any} import_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_import_line_path = __jsr.r({"import_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"import_id"],[2,[7,"/"],[2,[6,"lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/imports/:import_id/lines(.:format)
 * @param {any} import_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_import_lines_url = __jsr.r({"import_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"import_id"],[2,[7,"/"],[2,[6,"lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/imports/:import_id/lines(.:format)
 * @param {any} import_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_import_lines_path = __jsr.r({"import_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"import_id"],[2,[7,"/"],[2,[6,"lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_imports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/imports/summaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_imports_summaries_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"summaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/imports/summaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_imports_summaries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"summaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/payees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payee_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/payees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/payee_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payee_user_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payee_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/payee_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payee_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payee_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/payee_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payee_users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payee_users"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/payee_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payee_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payee_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/payees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payees_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/payees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/payees/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payees_exports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/payees/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payees_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/payment_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payment_fields_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payment_fields"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/payment_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_payment_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payment_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/releases/:release_id/attachments(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_release_attachments_url = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/releases/:release_id/attachments(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_release_attachments_path = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/releases/:release_id/cover(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_release_cover_url = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"cover"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/releases/:release_id/cover(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_release_cover_path = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"cover"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/release_tracks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_release_track_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"release_tracks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/release_tracks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_release_track_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"release_tracks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/releases/:release_id/tracks(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_release_tracks_url = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/releases/:release_id/tracks(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_release_tracks_path = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/reserves/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_reserve_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"reserves"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/reserves/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_reserve_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"reserves"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/reserves(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_reserves_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"reserves"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/reserves(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_reserves_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"reserves"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/rightholders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_rightholder_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"rightholders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/rightholders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_rightholder_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"rightholders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/rightholders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_rightholders_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"rightholders"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/rightholders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_rightholders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"rightholders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/sales/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sale_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/sales/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sale_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sales_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/sales/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sales_exports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/sales/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sales_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sales/summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sales_summary_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"summary"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/sales/summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sales_summary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"summary"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/settings/billing_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_settings_billing_profile_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"billing_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/settings/billing_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_settings_billing_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"billing_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/sources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/sources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/source_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_template_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/source_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/source_templates/:source_template_id/channels/:id(.:format)
 * @param {any} source_template_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_template_channel_url = __jsr.r({"source_template_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"source_template_id"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/source_templates/:source_template_id/channels/:id(.:format)
 * @param {any} source_template_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_template_channel_path = __jsr.r({"source_template_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"source_template_id"],[2,[7,"/"],[2,[6,"channels"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/source_templates/:source_template_id/tests(.:format)
 * @param {any} source_template_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_template_tests_url = __jsr.r({"source_template_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"source_template_id"],[2,[7,"/"],[2,[6,"tests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/source_templates/:source_template_id/tests(.:format)
 * @param {any} source_template_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_template_tests_path = __jsr.r({"source_template_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"source_template_id"],[2,[7,"/"],[2,[6,"tests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/source_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_templates_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"source_templates"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/source_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_source_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"source_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sources_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/statements/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statement_exports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/statements/exports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statement_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/statements/:statement_id/statement_lines(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statement_statement_lines_url = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/statements/:statement_id/statement_lines(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statement_statement_lines_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statements_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /api/statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/statements/batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statements_batches_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"batches"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/statements/batches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statements_batches_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"batches"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/statements/closures(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statements_closures_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"closures"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/statements/closures(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_statements_closures_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"closures"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/tracks/:track_id/contributors/:id(.:format)
 * @param {any} track_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_track_contributor_url = __jsr.r({"track_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"track_id"],[2,[7,"/"],[2,[6,"contributors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/tracks/:track_id/contributors/:id(.:format)
 * @param {any} track_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_track_contributor_path = __jsr.r({"track_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"track_id"],[2,[7,"/"],[2,[6,"contributors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/tracks/:track_id/contributors(.:format)
 * @param {any} track_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_track_contributors_url = __jsr.r({"track_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"track_id"],[2,[7,"/"],[2,[6,"contributors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/tracks/:track_id/contributors(.:format)
 * @param {any} track_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_track_contributors_path = __jsr.r({"track_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"track_id"],[2,[7,"/"],[2,[6,"contributors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/user_sessions/check_2fa(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_user_sessions_check_2fa_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"user_sessions"],[2,[7,"/"],[2,[6,"check_2fa"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/user_sessions/check_2fa(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_user_sessions_check_2fa_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"user_sessions"],[2,[7,"/"],[2,[6,"check_2fa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/user_sessions/force_email_otp(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_user_sessions_force_email_otp_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"user_sessions"],[2,[7,"/"],[2,[6,"force_email_otp"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/user_sessions/force_email_otp(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_user_sessions_force_email_otp_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"user_sessions"],[2,[7,"/"],[2,[6,"force_email_otp"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/contracts/:id/edit/:part(.:format)
 * @param {any} id
 * @param {any} part
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_url = __jsr.r({"id":{"r":true},"part":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[2,[7,"/"],[2,[3,"part"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/contracts/:id/edit/:part(.:format)
 * @param {any} id
 * @param {any} part
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_path = __jsr.r({"id":{"r":true},"part":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[2,[7,"/"],[2,[3,"part"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/advances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_advances_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"advances"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/advances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_advances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"advances"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/auth/paypal/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_auth_paypal_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"paypal"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/auth/paypal/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_auth_paypal_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"paypal"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/bulk_import/contracts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_bulk_import_contracts_new_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"bulk_import"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/bulk_import/contracts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_bulk_import_contracts_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"bulk_import"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/artists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artist_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/artists/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artist_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/artists/:artist_id/releases(.:format)
 * @param {any} artist_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artist_releases_url = __jsr.r({"artist_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"artist_id"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/artists/:artist_id/releases(.:format)
 * @param {any} artist_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artist_releases_path = __jsr.r({"artist_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"artist_id"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/artists/:artist_id/tracks(.:format)
 * @param {any} artist_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artist_tracks_url = __jsr.r({"artist_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"artist_id"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/artists/:artist_id/tracks(.:format)
 * @param {any} artist_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artist_tracks_path = __jsr.r({"artist_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"artist_id"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/artists/:artist_id/videos(.:format)
 * @param {any} artist_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artist_videos_url = __jsr.r({"artist_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"artist_id"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/artists/:artist_id/videos(.:format)
 * @param {any} artist_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artist_videos_path = __jsr.r({"artist_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"artist_id"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artists_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_artists_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_dashboard_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/merchandisings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_merchandising_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/merchandisings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_merchandising_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/merchandisings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_merchandisings_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/merchandisings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_merchandisings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/releases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_release_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/releases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_release_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/releases/:release_id/duplicates(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_release_duplicates_url = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"duplicates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/releases/:release_id/duplicates(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_release_duplicates_path = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"duplicates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/releases/:release_id/tracks/:id(.:format)
 * @param {any} release_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_release_track_url = __jsr.r({"release_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/releases/:release_id/tracks/:id(.:format)
 * @param {any} release_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_release_track_path = __jsr.r({"release_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/releases/:release_id/tracks(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_release_tracks_url = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/releases/:release_id/tracks(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_release_tracks_path = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/releases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_releases_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/releases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_releases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/tracks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_track_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/tracks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_track_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/tracks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_tracks_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/tracks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_tracks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"tracks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_video_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/videos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_videos_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/videos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_catalog_videos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_contract_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_contracts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_costs_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"costs"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_costs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"costs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/costs/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_costs_categories_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"costs"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/costs/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_costs_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"costs"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_dashboard_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/expired_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_expired_subscription_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"expired_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/expired_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_expired_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"expired_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/imports/:import_id/errors(.:format)
 * @param {any} import_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_import_errors_url = __jsr.r({"import_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"import_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/imports/:import_id/errors(.:format)
 * @param {any} import_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_import_errors_path = __jsr.r({"import_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[3,"import_id"],[2,[7,"/"],[2,[6,"errors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_imports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/imports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/imports/demo_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_imports_demo_files_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"demo_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/imports/demo_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_imports_demo_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"demo_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/insights/businesses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_insights_businesses_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"businesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/insights/businesses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_insights_businesses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"businesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/insights/explorers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_insights_explorers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"explorers"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/insights/explorers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_insights_explorers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"explorers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/insights/globals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_insights_globals_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"globals"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/insights/globals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_insights_globals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"globals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/integrations/wise(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_integrations_wise_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[6,"wise"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/integrations/wise(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_integrations_wise_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[6,"wise"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/invoices/:invoice_id/pdf(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_invoice_pdf_url = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/invoices/:invoice_id/pdf(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_invoice_pdf_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_invoices_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/imports/merlin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_merlin_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/imports/merlin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_merlin_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/imports/merlin/:merlin_id/items/:id(.:format)
 * @param {any} merlin_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_merlin_item_url = __jsr.r({"merlin_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[2,[7,"/"],[2,[3,"merlin_id"],[2,[7,"/"],[2,[6,"items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/imports/merlin/:merlin_id/items/:id(.:format)
 * @param {any} merlin_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_merlin_item_path = __jsr.r({"merlin_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[2,[7,"/"],[2,[3,"merlin_id"],[2,[7,"/"],[2,[6,"items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/imports/merlin/:merlin_id/items(.:format)
 * @param {any} merlin_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_merlin_items_url = __jsr.r({"merlin_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[2,[7,"/"],[2,[3,"merlin_id"],[2,[7,"/"],[2,[6,"items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/imports/merlin/:merlin_id/items(.:format)
 * @param {any} merlin_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_merlin_items_path = __jsr.r({"merlin_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[2,[7,"/"],[2,[3,"merlin_id"],[2,[7,"/"],[2,[6,"items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/onboarding/billing_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_onboarding_billing_profile_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"billing_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/onboarding/billing_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_onboarding_billing_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"billing_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/onboarding/logo_personalizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_onboarding_logo_personalizations_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"logo_personalizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/onboarding/logo_personalizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_onboarding_logo_personalizations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"logo_personalizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/onboarding(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_onboarding_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/onboarding(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_onboarding_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/onboarding/royalty_sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_onboarding_royalty_sources_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"royalty_sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/onboarding/royalty_sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_onboarding_royalty_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"royalty_sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/payees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_payee_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/payees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_payee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/payee_users/invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_payee_users_invite_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"payee_users"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/payee_users/invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_payee_users_invite_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"payee_users"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/payees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_payees_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/payees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_payees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/reserves(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_reserves_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"reserves"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/reserves(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_reserves_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"reserves"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /app(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /app/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_sales_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/sales(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_sales_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"sales"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/user_accesses/:id/resend_invitation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_accesses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/user_accesses/:id/resend_invitation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_accesses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/currency(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_currency_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"currency"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/currency(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_currency_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"currency"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/domestic_tax_rate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_domestic_tax_rate_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"domestic_tax_rate"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/domestic_tax_rate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_domestic_tax_rate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"domestic_tax_rate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/financial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_financial_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"financial"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/financial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_financial_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"financial"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/foreign_tax_rule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_foreign_tax_rule_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"foreign_tax_rule"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/foreign_tax_rule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_foreign_tax_rule_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"foreign_tax_rule"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/geozones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_geozones_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"geozones"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/geozones(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_geozones_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"geozones"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/integrations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_integrations_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"integrations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/integrations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_integrations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"integrations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_invoices_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/outbound_email_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_outbound_email_options_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"outbound_email_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/outbound_email_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_outbound_email_options_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"outbound_email_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/payment_methods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_payment_method_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/payment_methods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/payment_methods/:payment_method_id/default(.:format)
 * @param {any} payment_method_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_payment_method_default_url = __jsr.r({"payment_method_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"payment_method_id"],[2,[7,"/"],[2,[6,"default"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/payment_methods/:payment_method_id/default(.:format)
 * @param {any} payment_method_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_payment_method_default_path = __jsr.r({"payment_method_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"payment_method_id"],[2,[7,"/"],[2,[6,"default"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/payment_methods(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_payment_methods_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/payment_methods(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_payment_methods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/records(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_records_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/records(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_records_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/release_configs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_release_configs_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"release_configs"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/release_configs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_release_configs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"release_configs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/self_billing_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_self_billing_options_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"self_billing_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/self_billing_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_self_billing_options_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"self_billing_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/sources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_source_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/sources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_source_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/sources/:source_id/source_templates/:id(.:format)
 * @param {any} source_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_source_source_template_url = __jsr.r({"source_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"source_id"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/sources/:source_id/source_templates/:id(.:format)
 * @param {any} source_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_source_source_template_path = __jsr.r({"source_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"source_id"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/sources/:source_id/source_templates(.:format)
 * @param {any} source_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_source_source_templates_url = __jsr.r({"source_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"source_id"],[2,[7,"/"],[2,[6,"source_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/sources/:source_id/source_templates(.:format)
 * @param {any} source_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_source_source_templates_path = __jsr.r({"source_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"source_id"],[2,[7,"/"],[2,[6,"source_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_sources_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/statement_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_statement_options_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"statement_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/statement_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_statement_options_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"statement_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_subscriptions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/termination(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_termination_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"termination"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/termination(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_termination_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"termination"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/two_factor_auth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_two_factor_auth_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"two_factor_auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/two_factor_auth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_two_factor_auth_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"two_factor_auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/user_accesses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_user_access_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_accesses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/user_accesses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_user_access_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_accesses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/user_accesses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_user_accesses_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_accesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/user_accesses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_user_accesses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_accesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/user_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_user_profile_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/user_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_settings_user_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/statements/:statement_id/draft(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_statement_draft_url = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"draft"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/statements/:statement_id/draft(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_statement_draft_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"draft"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/statements/:statement_id/print(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_statement_print_url = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/statements/:statement_id/print(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_statement_print_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/statements/:statement_id/statement_lines(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_statement_statement_lines_url = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/statements/:statement_id/statement_lines(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_statement_statement_lines_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_statements_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"statements"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /app/statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const app_statements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"statements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_api_contract_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:id/archive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_api_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/statements/archive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_api_statements_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/statements/archive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const archive_api_statements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"archive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artist_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artist_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artist_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artist_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /artists/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artist_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artist_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artist_unlock_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artist_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/contracts/:id(/:part)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_url = __jsr.r({"id":{"r":true},"part":{},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"part"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/contracts/:id(/:part)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_path = __jsr.r({"id":{"r":true},"part":{},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"part"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/acceptances/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_acceptance_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"acceptances"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/acceptances/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_acceptance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"acceptances"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/advances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_advances_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"advances"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/advances(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_advances_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"advances"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/auth/paypal/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_auth_paypal_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"paypal"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/auth/paypal/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_auth_paypal_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"paypal"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/company_relationships/:company_relationship_id/confirm(.:format)
 * @param {any} company_relationship_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_company_relationship_confirm_url = __jsr.r({"company_relationship_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"company_relationships"],[2,[7,"/"],[2,[3,"company_relationship_id"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/company_relationships/:company_relationship_id/confirm(.:format)
 * @param {any} company_relationship_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_company_relationship_confirm_path = __jsr.r({"company_relationship_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"company_relationships"],[2,[7,"/"],[2,[3,"company_relationship_id"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_contract_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_contracts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_costs_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"costs"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/costs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_costs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"costs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/costs/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_costs_categories_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"costs"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/costs/categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_costs_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"costs"],[2,[7,"/"],[2,[6,"categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/expired_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_expired_subscription_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"expired_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/expired_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_expired_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"expired_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/insights/explorers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_insights_explorers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"explorers"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/insights/explorers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_insights_explorers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"explorers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/insights/globals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_insights_globals_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"globals"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/insights/globals(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_insights_globals_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"insights"],[2,[7,"/"],[2,[6,"globals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/invoices/:invoice_id/pdf(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_invoice_pdf_url = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/invoices/:invoice_id/pdf(.:format)
 * @param {any} invoice_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_invoice_pdf_path = __jsr.r({"invoice_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"invoice_id"],[2,[7,"/"],[2,[6,"pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_invoices_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/payee_users/invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_payee_users_invite_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payee_users"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/payee_users/invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_payee_users_invite_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payee_users"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/payees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_payees_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/payees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_payees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/releases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_release_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/releases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_release_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/releases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_releases_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/releases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_releases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"releases"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /artists(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /artists/settings/payee_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_payee_details_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payee_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/settings/payee_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_payee_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payee_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/settings/payee_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_payee_users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payee_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/settings/payee_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_payee_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payee_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/settings/payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_payments_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/settings/payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/settings/self_billing_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_self_billing_options_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"self_billing_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/settings/self_billing_options(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_self_billing_options_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"self_billing_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/settings/user_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_user_profile_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/settings/user_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_settings_user_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/statements/:statement_id/print(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_statement_print_url = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/statements/:statement_id/print(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_statement_print_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/statements/:statement_id/statement_lines(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_statement_statement_lines_url = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/statements/:statement_id/statement_lines(.:format)
 * @param {any} statement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_statement_statement_lines_path = __jsr.r({"statement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[3,"statement_id"],[2,[7,"/"],[2,[6,"statement_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_statements_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const artists_statements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_admin_users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admin_users/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_companies_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/provider_accounts/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_provider_accounts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/provider_accounts/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_provider_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/source_template_specs/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_source_template_specs_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_template_specs/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_source_template_specs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_artist_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_artist_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_admin_source_template_spec_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_admin_source_template_spec_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/payment_methods/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_app_settings_payment_methods_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/payment_methods/confirm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_app_settings_payment_methods_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_admin_user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_admin_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_artist_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_artist_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/two_factor_auth/disable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_app_settings_two_factor_auth_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"two_factor_auth"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/two_factor_auth/disable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_app_settings_two_factor_auth_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"two_factor_auth"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:id/draft_changelog(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const draft_changelog_api_contract_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"draft_changelog"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:id/draft_changelog(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const draft_changelog_api_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"draft_changelog"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_admin_user_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admin_users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/provider_accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_provider_account_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/provider_accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_provider_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_source_template_spec_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_source_template_spec_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/merchandisings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_catalog_merchandising_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/merchandisings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_catalog_merchandising_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/releases/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_catalog_release_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/releases/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_catalog_release_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/tracks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_catalog_track_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/tracks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_catalog_track_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/videos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_catalog_video_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/videos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_catalog_video_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/contracts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_contract_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/contracts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/imports/merlin/:merlin_id/items/:id/edit(.:format)
 * @param {any} merlin_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_merlin_item_url = __jsr.r({"merlin_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[2,[7,"/"],[2,[3,"merlin_id"],[2,[7,"/"],[2,[6,"items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/imports/merlin/:merlin_id/items/:id/edit(.:format)
 * @param {any} merlin_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_merlin_item_path = __jsr.r({"merlin_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[2,[7,"/"],[2,[3,"merlin_id"],[2,[7,"/"],[2,[6,"items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/payees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_payee_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/payees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_payee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/billing_profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_billing_profile_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"billing_profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/billing_profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_billing_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"billing_profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/domestic_tax_rate/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_domestic_tax_rate_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"domestic_tax_rate"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/domestic_tax_rate/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_domestic_tax_rate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"domestic_tax_rate"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/financial/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_financial_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"financial"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/financial/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_financial_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"financial"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/foreign_tax_rule/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_foreign_tax_rule_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"foreign_tax_rule"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/foreign_tax_rule/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_foreign_tax_rule_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"foreign_tax_rule"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/geozones/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_geozone_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"geozones"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/geozones/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_geozone_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"geozones"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/payment_methods/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_payment_method_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/payment_methods/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_payment_method_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/sources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_source_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/sources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_source_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/sources/:source_id/source_templates/:id/edit(.:format)
 * @param {any} source_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_source_source_template_url = __jsr.r({"source_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"source_id"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/sources/:source_id/source_templates/:id/edit(.:format)
 * @param {any} source_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_source_source_template_path = __jsr.r({"source_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"source_id"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/statement_options/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_statement_options_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"statement_options"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/statement_options/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_statement_options_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"statement_options"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/subscriptions/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_subscriptions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/subscriptions/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/user_profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_user_profile_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/user_profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_app_settings_user_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_artist_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_artist_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_artist_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_artist_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/settings/user_profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_artists_settings_user_profile_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/settings/user_profile/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_artists_settings_user_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_profile"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/:id/edit_two_factor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_two_factor_admin_admin_user_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_two_factor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admin_users/:id/edit_two_factor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_two_factor_admin_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_two_factor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /exports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /exports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/export_all_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_all_users_admin_companies_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"export_all_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/export_all_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_all_users_admin_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"export_all_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/export_catalog(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_catalog_admin_company_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_catalog"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/export_catalog(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_catalog_admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_catalog"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/export_payees(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_payees_admin_company_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_payees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/export_payees(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_payees_admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"export_payees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/artists/statements/exports/fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fields_api_artists_exports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/artists/statements/exports/fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fields_api_artists_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/statements/exports/fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fields_api_statement_exports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/statements/exports/fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fields_api_statement_exports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/two_factor_auth/generate_qr(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_qr_app_settings_two_factor_auth_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"two_factor_auth"],[2,[7,"/"],[2,[6,"generate_qr"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/two_factor_auth/generate_qr(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_qr_app_settings_two_factor_auth_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"two_factor_auth"],[2,[7,"/"],[2,[6,"generate_qr"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /graphiql
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const graphiql_rails_url = __jsr.r({}, [2,[7,"/"],[6,"graphiql"]], true);

/**
 * Generates rails route to
 * /graphiql
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const graphiql_rails_path = __jsr.r({}, [2,[7,"/"],[6,"graphiql"]]);

/**
 * Generates rails route to
 * /healthcheck(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const healthcheck_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"healthcheck"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /healthcheck(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const healthcheck_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"healthcheck"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/contracts/index_by_target_contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_by_target_contracts_api_contracts_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"index_by_target_contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/index_by_target_contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_by_target_contracts_api_contracts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"index_by_target_contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /invitations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invitation_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /invitations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invitation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invitations/:invitation_id/registrations(.:format)
 * @param {any} invitation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invitation_registrations_url = __jsr.r({"invitation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"invitation_id"],[2,[7,"/"],[2,[6,"registrations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /invitations/:invitation_id/registrations(.:format)
 * @param {any} invitation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invitation_registrations_path = __jsr.r({"invitation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invitations"],[2,[7,"/"],[2,[3,"invitation_id"],[2,[7,"/"],[2,[6,"registrations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/json(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const json_admin_source_template_spec_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"json"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/json(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const json_admin_source_template_spec_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"json"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_admin_user_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admin_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_company_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/provider_accounts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_provider_account_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/provider_accounts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_provider_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provider_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/source_template_specs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_source_template_spec_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_template_specs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_source_template_spec_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /admin/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_unlock_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/artists/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_artist_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/artists/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_artist_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/artists/:artist_id/releases/new(.:format)
 * @param {any} artist_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_artist_release_url = __jsr.r({"artist_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"artist_id"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/artists/:artist_id/releases/new(.:format)
 * @param {any} artist_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_artist_release_path = __jsr.r({"artist_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[3,"artist_id"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/bulk_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_bulk_import_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"bulk_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/bulk_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_bulk_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"bulk_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_import_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/merchandisings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_merchandising_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/merchandisings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_merchandising_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"merchandisings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/catalog/releases/:release_id/tracks/new(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_release_track_url = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/catalog/releases/:release_id/tracks/new(.:format)
 * @param {any} release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_catalog_release_track_path = __jsr.r({"release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"catalog"],[2,[7,"/"],[2,[6,"releases"],[2,[7,"/"],[2,[3,"release_id"],[2,[7,"/"],[2,[6,"tracks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/costs/bulk_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_costs_bulk_import_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"costs"],[2,[7,"/"],[2,[6,"bulk_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/costs/bulk_imports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_costs_bulk_import_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"costs"],[2,[7,"/"],[2,[6,"bulk_imports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/imports/merlin/:merlin_id/items/new(.:format)
 * @param {any} merlin_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_merlin_item_url = __jsr.r({"merlin_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[2,[7,"/"],[2,[3,"merlin_id"],[2,[7,"/"],[2,[6,"items"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/imports/merlin/:merlin_id/items/new(.:format)
 * @param {any} merlin_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_merlin_item_path = __jsr.r({"merlin_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"merlin"],[2,[7,"/"],[2,[3,"merlin_id"],[2,[7,"/"],[2,[6,"items"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/onboarding/onboarding/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_onboarding_onboarding_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/onboarding/onboarding/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_onboarding_onboarding_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/sales/breakage_revenue/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_sales_breakage_revenue_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"breakage_revenue"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/sales/breakage_revenue/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_sales_breakage_revenue_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"breakage_revenue"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/sales/exports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_sales_export_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/sales/exports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_sales_export_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"sales"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/financial/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_financial_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"financial"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/financial/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_financial_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"financial"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/geozones/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_geozone_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"geozones"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/geozones/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_geozone_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"geozones"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/payment_methods/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_payment_method_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/payment_methods/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_payment_method_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"payment_methods"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/release_configs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_release_configs_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"release_configs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/release_configs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_release_configs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"release_configs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_source_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/sources/:source_id/source_templates/new(.:format)
 * @param {any} source_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_source_source_template_url = __jsr.r({"source_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"source_id"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/sources/:source_id/source_templates/new(.:format)
 * @param {any} source_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_source_source_template_path = __jsr.r({"source_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[3,"source_id"],[2,[7,"/"],[2,[6,"source_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/termination/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_termination_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"termination"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/termination/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_termination_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"termination"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/user_accesses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_user_access_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_accesses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/user_accesses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_app_settings_user_access_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"user_accesses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artist_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artist_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artist_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artist_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artist_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /artists/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artist_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /artists/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artist_unlock_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artist_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /artists/acceptances/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artists_acceptance_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"acceptances"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /artists/acceptances/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_artists_acceptance_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"artists"],[2,[7,"/"],[2,[6,"acceptances"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_invitation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/payment_groups/:id/pay(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pay_api_payment_group_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payment_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pay"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/payment_groups/:id/pay(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pay_api_payment_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payment_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pay"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /privacy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /privacy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pg_extras
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_url = __jsr.r({}, [2,[7,"/"],[6,"pg_extras"]], true);

/**
 * Generates rails route to
 * /pg_extras
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_path = __jsr.r({}, [2,[7,"/"],[6,"pg_extras"]]);

/**
 * Generates rails route to
 * /pg_extras/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_queries_url = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /pg_extras/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pg_extras/actions/kill_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_kill_all_action_url = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]],[2,[6,"actions"],[2,[7,"/"],[2,[6,"kill_all"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pg_extras/actions/kill_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_kill_all_action_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]],[2,[6,"actions"],[2,[7,"/"],[2,[6,"kill_all"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pg_extras/actions/pg_stat_statements_reset(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_pg_stat_statements_reset_action_url = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]],[2,[6,"actions"],[2,[7,"/"],[2,[6,"pg_stat_statements_reset"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pg_extras/actions/pg_stat_statements_reset(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_pg_stat_statements_reset_action_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]],[2,[6,"actions"],[2,[7,"/"],[2,[6,"pg_stat_statements_reset"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pg_extras/actions/add_extensions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_add_extensions_action_url = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]],[2,[6,"actions"],[2,[7,"/"],[2,[6,"add_extensions"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /pg_extras/actions/add_extensions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_add_extensions_action_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]],[2,[6,"actions"],[2,[7,"/"],[2,[6,"add_extensions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pg_extras/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_root_url = __jsr.r({}, [2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]], true);

/**
 * Generates rails route to
 * /pg_extras/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_pg_extras_web_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"pg_extras"]],[7,"/"]]);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/ready(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ready_admin_source_template_spec_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ready"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/ready(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ready_admin_source_template_spec_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ready"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_user_invitation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/reopen(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reopen_admin_source_template_spec_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reopen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/source_template_specs/:id/reopen(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reopen_admin_source_template_spec_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"source_template_specs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reopen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/resend_active_invitation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_active_invitation_admin_company_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend_active_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/resend_active_invitation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_active_invitation_admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend_active_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/companies/:id/resync_insights(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resync_insights_admin_company_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resync_insights"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/resync_insights(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resync_insights_admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resync_insights"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:id/rollback(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rollback_api_contract_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"rollback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:id/rollback(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rollback_api_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"rollback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_url = __jsr.r({}, [7,"/"], true);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /api/payment_groups/:id/send_to_service(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_service_api_payment_group_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payment_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_service"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/payment_groups/:id/send_to_service(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_to_service_api_payment_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"payment_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_service"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /app/settings/two_factor_auth/set_second_factor_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_second_factor_email_app_settings_two_factor_auth_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"two_factor_auth"],[2,[7,"/"],[2,[6,"set_second_factor_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /app/settings/two_factor_auth/set_second_factor_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_second_factor_email_app_settings_two_factor_auth_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"app"],[2,[7,"/"],[2,[6,"settings"],[2,[7,"/"],[2,[6,"two_factor_auth"],[2,[7,"/"],[2,[6,"set_second_factor_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_url = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]], true);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /admin/companies/:id/sign_as_admin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_as_admin_admin_company_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign_as_admin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/companies/:id/sign_as_admin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_as_admin_admin_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign_as_admin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /stop_impersonating(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stop_impersonating_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stop_impersonating"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /stop_impersonating(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stop_impersonating_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stop_impersonating"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /billing/webhooks
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_event_url = __jsr.r({}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[6,"webhooks"]]]], true);

/**
 * Generates rails route to
 * /billing/webhooks
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_event_path = __jsr.r({}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[6,"webhooks"]]]]);

/**
 * Generates rails route to
 * /billing/webhooks/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_event_root_url = __jsr.r({}, [2,[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[6,"webhooks"]]]],[7,"/"]], true);

/**
 * Generates rails route to
 * /billing/webhooks/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stripe_event_root_path = __jsr.r({}, [2,[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[6,"webhooks"]]]],[7,"/"]]);

/**
 * Generates rails route to
 * /terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"terms"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"terms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/imports/total_net_received_amount_for_import_ids(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const total_net_received_amount_for_import_ids_api_imports_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"total_net_received_amount_for_import_ids"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/imports/total_net_received_amount_for_import_ids(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const total_net_received_amount_for_import_ids_api_imports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"imports"],[2,[7,"/"],[2,[6,"total_net_received_amount_for_import_ids"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /recede_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_recede_historical_location_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recede_historical_location"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /recede_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_recede_historical_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recede_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /refresh_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_refresh_historical_location_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"refresh_historical_location"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /refresh_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_refresh_historical_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"refresh_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resume_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_resume_historical_location_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resume_historical_location"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /resume_historical_location(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const turbo_resume_historical_location_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resume_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/contracts/:id/unarchive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unarchive_api_contract_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unarchive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/contracts/:id/unarchive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unarchive_api_contract_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unarchive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/statements/unarchive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unarchive_api_statements_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"unarchive"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/statements/unarchive(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unarchive_api_statements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"statements"],[2,[7,"/"],[2,[6,"unarchive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/:id/update_two_factor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_two_factor_admin_admin_user_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_two_factor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /admin/admin_users/:id/update_two_factor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_two_factor_admin_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_two_factor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_authorize_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/google_oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_invitation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/amazon_s3(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_amazon_s3_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"amazon_s3"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /webhooks/amazon_s3(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_amazon_s3_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"amazon_s3"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/amazon_ses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_amazon_ses_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"amazon_ses"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /webhooks/amazon_ses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_amazon_ses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"amazon_ses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /webhooks/wise(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_wise_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"wise"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /webhooks/wise(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const webhooks_wise_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"webhooks"],[2,[7,"/"],[2,[6,"wise"],[1,[2,[8,"."],[3,"format"]]]]]]]);

