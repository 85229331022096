import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (['production', 'staging'].includes(window.AppContext?.railsEnv)) {
  Sentry.init({
    dsn: window.AppContext.sentryDsn,
    integrations: [new BrowserTracing()],
    environment: window.AppContext.railsEnv,
    release: window.AppContext.version,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });

  if (window.AppContext.userId) {
    Sentry.setUser({ id: window.AppContext.userId });
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
}
