import axios from 'axios';

axios.defaults.headers.common.Accept = "application/json";

const csrfTokenElem = document.querySelector("meta[name='csrf-token']");
const jwtElem = document.querySelector("meta[name='jwt']");

if (csrfTokenElem !== null) {
  const csrf = csrfTokenElem.getAttribute('content');
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf;
}

if (jwtElem !== null) {
  const jwt = jwtElem.getAttribute('content');
  axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
}
