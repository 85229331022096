import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["menu"];
	static classes = ["menu"];

	toggle() {
		this.menuClasses.map((className) =>
			this.menuTarget.classList.toggle(className),
		);
	}

	hide(e) {
		if (this.element.contains(e.target)) return;
		this.menuTarget.classList.add(...this.menuClasses);
	}
}
