import { Toaster, Position, Intent } from "@blueprintjs/core";

const setupToaster = () => {
	const AppToaster = Toaster.create(
		{
			className: "my-toaster",
			position: Position.BOTTOM,
		},
		document.body,
	);

	AppToaster.showNotice = (message, timeout = 5000, icon = "tick") => {
		AppToaster.show({
			message,
			intent: Intent.SUCCESS,
			timeout,
			icon,
		});
	};

	AppToaster.showAlert = (message, timeout = 5000, icon = "warning-sign") => {
		AppToaster.show({
			message,
			intent: Intent.DANGER,
			timeout,
			icon,
		});
	};

	AppToaster.showWarning = (message, timeout = 5000, icon = "warning-sign") => {
		AppToaster.show({
			message,
			intent: Intent.WARNING,
			timeout,
			icon,
		});
	};

	const noticeText = document
		.querySelector("meta[name='notice']")
		?.getAttribute("content");

	const alertText = document
		.querySelector("meta[name='alert']")
		?.getAttribute("content");

	if (noticeText !== null && noticeText !== undefined) {
		AppToaster.showNotice(noticeText);
	}

	if (alertText !== null && alertText !== undefined) {
		AppToaster.showAlert(alertText);
	}

	window.Toaster = AppToaster;
};

const tearDownToaster = () => {
	window.Toaster = null;
};

// Comment if using Turbokins

document.addEventListener("DOMContentLoaded", () => {
	setupToaster();
});

// Uncomment if using Turbokinks

// document.addEventListener('turbolinks:load', function() {
//   setupToaster()
// });

// document.addEventListener('turbolinks:before-visit', function() {
//   tearDownToaster()
// });

// document.addEventListener("turbolinks:before-cache", function() {
//   tearDownToaster()
// });
