import ReactRailsUJS from 'react_ujs';

// Asynchronously
import loadable from '@loadable/component';

// This line generates the following
// const components = {
//   './dir/foo.js': () => import('./dir/foo.js'),
//   './dir/bar.js': () => import('./dir/bar.js'),
// }
const components = import.meta.glob('./**/*.jsx');

ReactRailsUJS.getConstructor = (name) => loadable(components[`./${name}.jsx`]);

// Synchronously
// const eagerComponents = import.meta.globEager('./**/*.jsx');
// ReactRailsUJS.getConstructor = (name) => eagerComponents[`./${name}.jsx`].default;

// ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount, false);
// ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount, false);
// ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount, false);
